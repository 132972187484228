import { createServiceAxiosInstance } from './helper';
import { MtmService, HeimdallService, ArxiveService, AiStudioService } from '#lib/api';
import * as LegalcService from './legalc.service';

[MtmService, HeimdallService, ArxiveService, AiStudioService, LegalcService].forEach(service => {
    if ('setupServiceInstance' in service) {
        service.setupServiceInstance(createServiceAxiosInstance);
    }
});

export { MtmService, HeimdallService, ArxiveService, AiStudioService } from '#lib/api';
export * as LegalcService from './legalc.service';
