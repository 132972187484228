import { derived, writable, type Readable } from 'svelte/store';

interface MetaStore {
    getHomePathByProductType: (productType: string, workspaceName?: string) => string;
}

let prevWorkspaceName = '';
let prevProductMetaKey = '';

const aiStudioMeta = writable({} as Record<string, any>);

// productType 과 path를 매핑
const productTypeToPath = {
    AI_STUDIO: '/[workspaceName]/',
};

const getProductTypePath = (productType: string) => productTypeToPath?.[productType] || '/';

export const meta: Readable<MetaStore> = derived([aiStudioMeta], ([$ai], set) => {
    set({
        getHomePathByProductType: (productType: string, workspaceName?: string) => getProductTypePath(productType).replace('[workspaceName]', workspaceName || prevWorkspaceName),
    });
});

// Actions
export const resetMeta = () => {
    prevWorkspaceName = '';
    prevProductMetaKey = '';

    aiStudioMeta.set({});
};

export const fetchRefreshMetaInfo = async (workspaceName, productType?: keyof typeof productTypeToPath) => {
    const tempProductKey = `${workspaceName}_${productType}`;

    if (!workspaceName || prevProductMetaKey === tempProductKey) return;

    prevWorkspaceName = workspaceName;
    prevProductMetaKey = tempProductKey;
};
